<template>
    <profile-layout>
        <template #header>
            <div
                class="arrowWrap"
                @click="toProfile"
            >
                <arrow />
            </div>
            Change biography
        </template>
        <template #content>
            <div class="input-field input-field--bio">
                <form-textarea
                    v-model="biography"
                    :field.sync="biography"
                    legend="Enter biography"
                    :errors="biographyErrorText || null"
                    tabindex="2"
                    type="text"
                />
            </div>
            <password-modal
                :name="'password-test-name'"
                :title="'Change biography'"
            />
        </template>
        <template #footer>
            <custom-button
                default
                class="button btn-base_colored"
                :type="'submit'"
                tabindex="3"
                width="100%"
                @on-btn-click="validationData"
            >
                Submit
            </custom-button>
        </template>
    </profile-layout>
</template>

<script>
import {
    ACTION_GET_CURRENT_USER
} from '@/store/modules/auth/action-types'
import { createNamespacedHelpers, mapState } from 'vuex'

import { changeUserBio } from '@/services/profileService';
import ProfileLayout from '@/layouts/ProfileLayout'
import PasswordModal from '@/components/modals/PasswordModal';
import Arrow from '@/icons/Arrow'

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('auth')

export default {
    name: 'ChangeBiography',
    components: { ProfileLayout, Arrow, PasswordModal },
    data() {
        return {
            biography: '',
            biographyErrorText: '',
            isCreated: false
        }
    },
    computed: {
        ...mapState('auth', ['user'])
    },
    watch: {
        biography() {
            if (!this.isCreated) {
                this.isCreated = true
                return
            }
            this.validate()
        }
    },
    activated() {
        document.querySelector('textarea').focus()
        this.$bus.$on('confirm', this.submit)
    },
    deactivated() {
        this.$bus.$off('confirm', this.submit)
    },
    created() {
        this.biography = this.user.biography
    },
    methods: {
        ...mapAdminActions([
            ACTION_GET_CURRENT_USER
        ]),
        toProfile() {
            this.$router.push({ name: 'user-profile' })
        },
        reset() {
            this.biography = '';
        },
        validate() {
            if (!this.biography) {
                this.biographyErrorText = 'Biography is a required field'
                return false
            }
            if (this.biography.length < 50) {
                this.biographyErrorText = 'Must be at least 50 characters.'
                return false
            }
            if (this.biography.length > 300) {
                this.biographyErrorText = 'Must be at least 300 characters.'
                return false
            }
            this.biographyErrorText = ''
            return true
        },
        validationData() {
            this.biographyErrorText = ''
            if (this.validate()) {
                this.$bus.$emit('openModal', 'password-test-name')
            }
        },
        submit(name) {
            if (name !== 'password-test-name') { return }
            changeUserBio({ biography: this.biography })
                .then(async () => {
                    await this[ACTION_GET_CURRENT_USER]()
                    this.$bus.$emit('showSnackBar', 'Your user biography was changed', 'success')
                    this.reset()
                    this.toProfile()
                }).catch((e) => {
                    this.$bus.$emit('showSnackBar', e.response?.data?.message || 'Invalid data', 'error')
                })
        }
    }
}
</script>

<style lang="scss">
.profile__body{
    .input-field--bio {
        .form-textarea__input{
            min-height: 150px;
        }
    }
}

</style>
